<script setup>
import { inject } from 'vue';

// Components
import Modal from '@/Components/Popup/Modal.vue';

// Partials
import Title from '@/Components/Title/Title.vue';
import UpdateReviewForm from '@/Pages/Staff/Reviews/Partials/UpdateReviewForm.vue';

const dayjs = inject('dayjs');
const route = inject('route');
const props = defineProps({
    showing: Boolean,
    review: Object,
});

const emits = defineEmits(['update:showing', 'nextRowClicked']);
const closeForm = () => emits('update:showing', false);

const sendNextRow = (payload) => {
    emits('nextRowClicked', payload);
};

</script>

<template>
    <Modal :open='showing' class='sm:max-w-[400px]' @closing='closeForm'>
        <template #default>


            <Title :sub-title="review.date +', '+ review.detail" :title='review.position'>
            </Title>

            <div class='flex space-x-20 '>


                <div class='flex flex-col'>
                    <div v-if='review.subject_name' class='mb-6 text-blue'>
                        <h3 class='mb-2 text-base font-bold'>{{ $t('Reviewer') }}</h3>
                        <p>{{ review.subject_name }}</p>
                    </div>
                    <div v-if='review.reviewer_name' class='mb-6 text-blue'>
                        <h3 class='mb-2 text-base font-bold'>{{ $t('Review by') }}</h3>
                        <p>{{ review.reviewer_name }}</p>
                    </div>
                </div>


                <UpdateReviewForm
                    :review='review'
                    :showing='showing'
                />

            </div>
        </template>
    </Modal>
</template>
